import { useEffect, useRef, useState } from 'react';
import "./assets/css/style.scss";

function App() {
  // state & varaiable
  const { naver } = window;
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState(null);
  const [marker, setMarker] = useState(null);
  const height = window.innerHeight - 16;

  // function
  const submitHandler = async (e) => {
    e.preventDefault();
    naver.maps.Service.geocode({
        query: e.target[0].value
    }, function(status, response) {
        if (status === naver.maps.Service.Status.ERROR) {
            return ;
        }

        if (response.v2.meta.totalCount === 0) {
            return ;
        }

        setLocation({
          lat : response.v2.addresses[0].y, 
          lng : response.v2.addresses[0].x
        });
    });
  }
  // hook
  useEffect(() => {
    if(location) {
      const mapOptions = {
        center: new naver.maps.LatLng(location.lat, location.lng),
        zoom: 20,
      };
      if(!map) {
        const naverMap = new naver.maps.Map('map', mapOptions);
        setMap(naverMap);
      }
    }
  },[map, mapRef, location, naver.maps.Map, naver.maps.LatLng])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      },
      (error) => {
        setLocation({
          la: 37.3595704,
          lng: 127.105399,
        })
        alert('현재 위치를 알 수 없습니다.')
      },
    );
  },[])

  useEffect(() => {
    if(map && location) {
      const lat = parseFloat(location.lat);
      const lng = parseFloat(location.lng);
      const coords = new naver.maps.LatLng(lat, lng);
      if(marker) {
        marker.setPosition(coords)
        map.setCenter(coords);
      } else {
        setMarker(new naver.maps.Marker({
            position: coords,
            map : map,
        }))
      }
    }
  },[map, location, marker, naver.maps.LatLng, naver.maps.Marker])

  return (
    <>
      <div id='map' ref={mapRef} style={{height:height}}>
        <form className='search-bar' onSubmit={submitHandler}> {/* form get을 하는 경우 해당 경로로 이동이 되어버리므로 rest api 사용을 위해서는 function 사용. */}
          <input name='addr' type="text" />
          <button type='submit'>검색</button>
        </form>
      </div>
    </> 
  );
}

export default App;
